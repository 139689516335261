.pod{
    border: 1px solid #f3f3f3;
    /* border-radius: 5px; */
    box-shadow: rgba(31, 31, 31, 0.561) 0px 1px 4px 0px;
}

.pod-header{
    background-color: #f3f3f3;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    padding: 5px;
    border: none;
    color: #3b3b3b;
}

.pod-body{
    margin: 10px 0 10px 0;

}