.cw-loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cw-loading.cw-loading-delay {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation: cw-loadingDelay 300ms ease-in-out 1000ms both;
    animation: cw-loadingDelay 300ms ease-in-out 1000ms both;
}

@-webkit-keyframes cw-loadingDelay {
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes cw-loadingDelay {
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.cw-loading-spinner{
    box-sizing: border-box;
    display: inline-block;
    padding: 0.7em;
    vertical-align: middle;
    text-align: center;
    background-color: transparent;
    border: 4px solid transparent;
    border-top-color: #026ccf;
    border-bottom-color: #026ccf;
    border-radius: 50%;
}

.cw-loading-spinner-child-outer{
    -webkit-animation: spin 1s infinite;
    animation: spin 1s infinite;
}

.cw-loading-spinner-child-inner{
    -webkit-animation: spin 1s infinite;
    animation: spin 1s infinite;
}

@keyframes spin{
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}