.pseudo-link {
    color: blue;
    cursor: pointer;
}

input.invalid-field {
    outline: 2px solid red;
}

.invalid-field-text {
    color: red;
}

.table-paging-decription {
    float: left;
}

.table-paging-buttons {
    text-align: right;
}

.col-invoice-select {
    width: 4%;
    display: inline-block;
    text-align: center;
}

.col-invoice-date {
    width: 12%;
    display: inline-block;
    text-align: center;
}

.col-header-invoice-number {
    width: 12%;
    display: inline-block;
    text-align: center;
}

.col-invoice-number {
    width: 15%;
    display: inline-block;
    text-align: left;
}

.col-invoice-company-name {
    width: 30%;
    display: inline-block;
    text-align: left;
}

.col-header-invoice-company-name {
    width: 30%;
    display: inline-block;
    text-align: center;
}

.col-header-invoice-account-number {
    width: 25%;
    display: inline-block;
    text-align: center;
}

.col-invoice-account-number {
    width: 25%;
    display: inline-block;
    text-align: left;
}

.col-header-total {
    width: 15%;
    display: inline-block;
    text-align: center;
}

.col-total {
    width: 17%;
    display: inline-block;
    text-align: right;
}

.list-view-row {
    display: flex;
    align-items: center;
}

.list-view > .list-view-row:nth-child(odd) {
    background: #e6e6e6;
}

.list-view-row > div {
    padding-right: 15px;
    padding-left: 15px;
}

.invoice-totals {
    margin-left: 5%;
}

.invoice-review {
    margin-top: 1%;
}

.col > .data-review-section:not(:first-child) {
    margin-top: 1%;
}

.invoice-total-warning {
    color: red;
}